import React, { useState } from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { animated } from 'react-spring'
import Layout from '../components/layout'

export default function Portfolio({ data, location }) {
  const [selectedCategory /*, setCategory*/] = useState(
    data.categories.list.find((category) => {
      if (location.hash === '') {
        return true
      }
      return category.tag === location.hash.split('#')[1]
    })
  )

  const [projects /*, setProjects*/] = useState(
    data.projects.list.filter(
      (project) =>
        !project.node.frontmatter.disabled &&
        (selectedCategory.tag === 'all' ||
          selectedCategory.tag === project.node.frontmatter.category)
    )
  )
  // const categoryClick = (category) => {
  //   setCategory(
  //     data.categories.list.find(
  //       (listedCategory) =>
  //         category === 'all' || listedCategory.tag === category
  //     )
  //   )
  //   setProjects(
  //     data.projects.list.filter(
  //       (project) =>
  //         !project.node.frontmatter.disabled &&
  //         (category === 'all' || category === project.node.frontmatter.category)
  //     )
  //   )
  // }

  return (
    <Layout location={location} title="Portfolio">
      {/* <nav className="flex">
        {data.categories.list.map((category, key) => {
          const selected =
            category.tag === selectedCategory.tag ? ' text-red-800' : ''
          return (
            <a
              key={key}
              href={'#' + category.tag}
              className={'pr-2 uppercase hover:text-red-800' + selected}
              onClick={() => categoryClick(category.tag)}
            >
              {category.name}
            </a>
          )
        })}
      </nav> */}
      <div className="mt-5 sm:col-count-3 col-count-1 col-w col-gap">
        {projects.map((project, key) => {
          return (
            <animated.div
              key={key}
              className="inline-block bg-one-200 p-3 m-0 mb-8 w-full shadow-lg rounded-lg"
            >
              {project.node.frontmatter.cover && (
                <Img fluid={project.node.frontmatter.cover.src.fluid} />
              )}
              <h1 className="text-base mt-4">
                {project.node.frontmatter.title}
              </h1>
              <h1 className="text-sm">{project.node.frontmatter.subtitle}</h1>
              <hr className="w-2/3 my-5 border-one-500 mx-auto" />
              <section
                className="text-sm mt-5 text-right"
                dangerouslySetInnerHTML={{ __html: project.node.html }}
              />
            </animated.div>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    categories: allCategoryItem {
      list: nodes {
        name
        tag
      }
    }
    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/projectos/" } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      list: edges {
        node {
          frontmatter {
            title
            subtitle
            category
            disabled
            cover {
              src: childImageSharp {
                fluid(maxWidth: 3375) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
